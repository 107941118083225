var bootstrap = require('bootstrap');
window.moment = require('moment');

// Configure moment.js
window.moment.locale('de');

// Enable tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(tooltipTriggerEl => { // eslint-disable-line no-unused-vars
    return new bootstrap.Tooltip(tooltipTriggerEl, {html: true}) // eslint-disable-line no-undef
});

// Admin area: User search
(() => {
    const adminUserSearchField = document.querySelector('.js-admin_user_search');
    const adminUserSearchTargets = document.querySelectorAll('.js-admin_user_search_target tr');
    const adminUserSearchNoResult = document.querySelector('.js-admin_user_search_not_found');

    if (adminUserSearchField) {
        adminUserSearchNoResult.style.display = 'none';

        adminUserSearchField.addEventListener('keyup', () => {
            let results = 0;
            let searchForWords = adminUserSearchField.value.toLowerCase().trim().split(' ');

            if (adminUserSearchField.value.length < 3) {
                adminUserSearchTargets.forEach(element => {
                    element.style.display = 'table-row';
                });
                adminUserSearchNoResult.style.display = 'none';
                return;
            }

            adminUserSearchTargets.forEach(element => {
                let found = 0;

                searchForWords.forEach(substring => {
                    if (element.innerHTML.toLowerCase().includes(substring.toLowerCase())) {
                        found++
                    }
                });

                if (found === searchForWords.length) {
                    results++;
                    element.style.display = 'table-row';
                } else {
                    element.style.display = 'none';
                }
            });

            results > 0 ? adminUserSearchNoResult.style.display = 'none' : adminUserSearchNoResult.style.display = 'block';
        });
    }
})();

// Admin area: When superadmin selects role "Regionalsekretär", set attribute "multiple" on according select
(() => {
    const adminSelectRole = document.querySelector('.js-admin_modify_fh_select');
    const adminSelectFH = document.querySelector('#university');

    if (adminSelectRole && adminSelectFH) {
        const toggleMultipleSelect = function () {
            adminSelectRole.value === 'regional-secretary' ? adminSelectFH.setAttribute('multiple', true) : adminSelectFH.removeAttribute('multiple');
        }

        toggleMultipleSelect();
        adminSelectRole.addEventListener('change', toggleMultipleSelect);
    }
})();
